<template>
  <div class='recruiter-layout'>
    <!-- <button @click="change()">切换到求职者</button> -->
    <div class="main">
      <div class="header">
        <div class="header-content">
          <div class="left" @click="isShowChangeCityDialog=true">
            <img src="../../assets/images/location-icon.png" alt="">
            <div>{{ currentCity.name }}</div>
          </div>
          <div class="center">
            <div class="item" :class="{ 'active': item.index == activeIndex, 'mr0': index == headerTabs.length - 1 }"
              v-for="(item, index) in headerTabs" :key="index" @click="handleTab(item)">{{ item.title }}</div>
          </div>
          <div class="right">
            <div class="av" @click="goPersonalCenter()">
              <img class="av" :src="avUrl" alt="">
            </div>
            <div class="set">
              <el-popover
                ref="setpopover"
                placement="bottom"
                popper-class="custom-popover-myself"
                class="custom-popover"
                trigger="click">
                <div class="cont">
                  <div class="item" v-for="(item, index) in setArr" :key="index"
                    @click="operate('setChange', item, index)">
                    <img :src="item.icon" alt="">
                    <span>{{ item.name }}</span>
                  </div>
                </div>
                <img slot="reference" src="../../assets/images/set-icon.png" alt="">
              </el-popover>
            </div>
            <div class="set">
              <el-popover
                ref="morepopover"
                placement="bottom"
                popper-class="custom-popover-myself"
                class="custom-popover"
                trigger="click">
                <div class="cont">
                  <div class="item" v-for="(item, index) in moreMenuArr" :key="index"
                    @click="operate('setChange', item, index)">
                    <img :src="item.icon" alt="">
                    <span>{{ item.name }}</span>
                  </div>
                </div>
                <img class="mr0" slot="reference" src="../../assets/images/more-icon.png" alt="">
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view class="router-view" ref="recRouterView" />
    <div class="footer">
      <div class="content">
        <div>
          <span @click="goAgreement(3)">关于我们</span>
          <span @click="goAgreement(1)">用户协议</span>
          <span @click="goAgreement(2)">隐私政策</span>
          <span @click="goAgreement(4)">平台资质</span>
        </div>
        <div class="copy-right">Copyright @2023 掘金之路(海南)科技有限公司 Al Right Reserved.琼ICP备2023008525号</div>
      </div>
    </div>

    <cityDialog v-if="isShowChangeCityDialog" :currentCity="currentCity" @closed="isShowChangeCityDialog=false" @selected="selectedCity"></cityDialog>
  </div>
</template>

<script>
import cityDialog from '@/components/cityDialog.vue'
import { TUILogin } from "@tencentcloud/tui-core";

export default {
  components: {
    cityDialog
  },
  data() {
    return {
      isShowChangeCityDialog: false,
      currentCity: {
        id: 2297,
        name: '三亚市'
      },
      activeIndex: 0,
      headerTabs: [
        {
          index: 0,
          title: '首页',
          path: '/recruiter/home'
        }, {
          index: 1,
          title: '职位',
          path: '/recruiter/publishjob'
        }, {
          index: 2,
          title: '消息',
          path: '/recruiter/message'
        },
      ],
      setArr: [{
        name: '账号与安全',
        path: '/recruiter/accountSecurityRecruiter',
        icon: require('@/assets/images/set-safety.png')
      }, {
        name: '关于我们',
        path: '/recruiter/agreement?type=3',
        icon: require('@/assets/images/set-about-us.png')
      }, {
        name: '用户协议',
        path: '/recruiter/agreement?type=1',
        icon: require('@/assets/images/order-icon.png')
      }, {
        name: '隐私政策',
        path: '/recruiter/agreement?type=2',
        icon: require('@/assets/images/set-incognito_mode_.png')
      }, {
        name: '平台资质',
        path: '/recruiter/agreement?type=4',
        icon: require('@/assets/images/set-certificate.png')
      }, {
        name: '退出登录',
        // path: '/preferences',
        icon: require('@/assets/images/set-exit.png'),
        type: 'outLogin'
      }],
      moreMenuArr: [
        {
          name: '公司信息',
          path: '/recruiter/company-profile',
          icon: require('@/assets/images/more-company-info.png')
        },{
          name: '在职感受',
          path: '/recruiter/workfeel',
          icon: require('@/assets/images/more-onjob-feel.png')
        },{
          name: '职位管理',
          path: '/recruiter/jobmanage',
          icon: require('@/assets/images/more-job-manager.png')
        },{
          name: '审核信息',
          path: '/recruiter/review-infomation',
          icon: require('@/assets/images/more-review-info.png')
        },{
          name: '常用语',
          path: '/recruiter/commonphrases',
          icon: require('@/assets/images/common-phrases-icon.png')
        },
      ]
    }
  },
  methods: {
    // change(){
    //   this.$router.replace('/')
    // },
    handleTab (item) {
      this.activeIndex = item.index
      this.$router.push(item.path)
    },
    operate (type, data) {
      switch (type) {
        case 'setChange': // 设置里面点击
          if (data.type == 'outLogin'){
            localStorage.clear()
            this.$router.push({
              path: '/login'
            })
            TUILogin.logout().then(res=>{
              // debugger
            }).catch(err=>{
              // debugger
            })
          } else {
            this.$router.push({
              path: data.path
            })
          }
          break
      }

      this.$refs.setpopover.doClose()
      this.$refs.morepopover.doClose()
    },
    goPersonalCenter(){
      this.$router.push('/recruiter/personalcenter')
    },
    getLocation() {
      const that = this;
      const geolocation = new BMap.Geolocation()
 
      geolocation.getCurrentPosition(function(r) {
        if (this.getStatus() == 0) {
          let geoc = new BMap.Geocoder();
          geoc.getLocation(r.point, function(rs) {
            const addComp = rs.addressComponents;
            let city = addComp.city; // 解析出的城市
            console.log('当前城市：' + city);
            // alert('定位成功！' + that.city);
            // wqtodo
            // that.currentCity = {
            //   name: that.city,
            //   id: 0,
            // }
            // that.setLocation()
          });
        } else {
          console.log('定位失败');
          // debugger
          // alert('定位失败！' + this.getStatus());
        }
      }, { enableHighAccuracy: true });
    },
    selectedCity(item) {
      console.log(item);
      this.currentCity = item
      this.isShowChangeCityDialog = false
      debugger
      if(window.location.pathname == '/recruiter/home'){
        this.$refs.recRouterView.updateCity(this.currentCity)
      }
    },
    goAgreement(type) {
      this.$router.push('agreement?type=' + type)
    }
  },
  mounted() {
    let isLogin = this.$store.getters.userIsLogin
    if(!isLogin) {
      this.$router.push('/login')
    }

    this.getLocation()
  },
  computed: {
    avUrl() {
      if(this.$store.getters.userInfo.avatar) {
        return this.$store.getters.imgeCDN + this.$store.getters.userInfo.avatar
      }
      return ''
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        if(to.path === '/recruiter/home'){
          this.activeIndex = 0
        } else if(to.path === '/recruiter/publishjob'){
          this.activeIndex = 1
        } else if(to.path === '/recruiter/message'){
          this.activeIndex = 2
        }
      },
      deep: true,
      immediate: true// 第一次初始化渲染就可以监听到
    }
  }
}
</script>

<style lang="less" scoped>
.recruiter-layout{
  height: 100%;
  background-color: gray;
  .main {
    .header-content {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 23px;
        color: #FFFFFF;
        cursor: pointer;

        img {
          width: 27px;
          height: 27px;
          margin-right: 7px;
        }
      }

      .center {
        display: flex;
        align-items: center;
        font-size: 23px;
        color: #FFFFFF;

        .item {
          cursor: pointer;
          margin-right: 60px;
          border-bottom: 3px solid rgba(0, 0, 0, 0);
        }

        .active {
          font-weight: 600;
          border-bottom: 3px solid white;
        }

        .mr0 {
          margin-right: 0;
        }
      }

      .right {
        display: flex;
        align-items: center;
        cursor: pointer;
        .av {
          width: 33px;
          height: 33px;
          margin-right: 33px;
          border-radius: 17px;
        }

        img {
          width: 30px;
          height: 30px;
          margin-right: 33px;
        }

        .mr0{
          margin-right: 0;
        }
      }
    }

    .header {
      height: 103px;
      background: #2676F7;
      position: sticky;
      top: 0;
      z-index: 100;
      display: flex;
      justify-content: center;
    }
  }

  .router-view{
    min-height: calc(100% - 321px);
    background-color: #F7F7F7;
  }
  .footer{
    width: 100%;
    height: 218px;
    background: #353840;
    display: flex;
    justify-content: center;
    .content{
      width: 1200px;
      padding-top: 63px;
      // background-color: cadetblue;
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;

      span{
        margin-right: 53px;
        cursor: pointer;
      }

      .copy-right{
        margin-top: 41px;
        font-weight: 400;
        font-size: 13px;
        color: #FFFFFF;
        opacity: 0.4;
      }
    }
  }
}
</style>
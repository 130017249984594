<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-24 19:19:20
 * @Description: 商城公用底部
-->
<template>
  <div class="footer">
    <div class="content">
      <span @click="operate(item.type)" v-for="(item,index) in footerArr" :key="index">{{ item.name }}</span>
    </div>
    <div class="copy-right">Copyright @2023 掘金之路(海南)科技有限公司 Al Right Reserved.琼ICP备2023008525号</div>
  </div>
</template>

<script>
export default {
  
  data() {
    return {
      footerArr: [{
        name: '关于我们',
        type: 3,
      },{
        name: '用户协议',
        type: 1,
      },{
        name: '隐私政策',
        type: 2,
      },{
        name: '平台资质',
        type: 4,
      }],
    }
  },
  methods: {
    operate(type) {
      this.$emit('footerTabClick', type)
    }
  }
}
</script>

<style lang="less" scoped>
.footer{
  width: 100%;
  height: 260px;
  padding: 0px 240px;
  background: #2B303B;
  border-radius: 1px;
  .content{
    padding-top: 76px;
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    span{
      margin-right: 48px;
      cursor: pointer;
    }
  }
  .copy-right{
    margin-top: 41px;
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
    opacity: 0.4;
  }
}
</style>